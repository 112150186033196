<template>
  <div>
    <BankPaymentNotVerifiedCorp
      v-if="isCorporateAccount"
      v-model="model"
      @close="closeModal"
      :isSendMoney="isSendMoney"
      :isNewAccount="isNewAccount"
    />
    <BankPaymentNotVerifiedConsumer v-else v-model="model" />
  </div>
</template>

<script>
import BankPaymentNotVerifiedConsumer from '@galileo/components/Views/PaymentMethod/BankPaymentNotVerified/BankPaymentNotVerifiedConsumer.vue'
import BankPaymentNotVerifiedCorp from '@galileo/components/Views/PaymentMethod/BankPaymentNotVerified/BankPaymentNotVerifiedCorp.vue'
import { ref } from '@vue/composition-api'
import { useVModel } from '@oen.web.vue2/ui'
import { useRouter } from '@galileo/composables/useRouter'

import { storeToRefs } from 'pinia'

import { useI18nStore, useAuthStore } from '@galileo/stores'

export default {
  name: 'BankPaymentNotVerified',
  components: {
    BankPaymentNotVerifiedConsumer,
    BankPaymentNotVerifiedCorp,
  },
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    isNewAccount: {
      type: Boolean,
      required: false,
      default: false,
    },
    isSendMoney: {
      type: Boolean,
      required: false,
      default: false,
    },
    openAsRoute: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  emits: ['input'],
  setup(props, { emit }) {
    const { $t } = useI18nStore()
    const authStore = useAuthStore()
    const router = useRouter()

    const { model } = useVModel(props, emit)
    const { isCorporateAccount } = storeToRefs(authStore)

    const closeModal = () => {
      if (props.openAsRoute) {
        router.toParentRoute()
      } else {
        model.value = false
      }
    }

    return {
      model,
      $t,
      closeModal,
      isCorporateAccount,
    }
  },
}
</script>

<style scoped>
p {
  @apply text-base;
}
</style>
