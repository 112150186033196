<template>
  <information-app-modal v-model="model" @close="closeModal" :icon="ICON_TYPE.WARNING_YELLOW">
    <h1 class="title">{{ $t('BankPaymentNotVerifiedCorp.Title').value }}</h1>

    <p>
      {{ $t('BankPaymentNotVerifiedCorp.Content').value }}
    </p>
    <p v-if="isNewAccount && isSendMoney">
      {{ $t('BankPaymentNotVerifiedCorp.SelectNewPaymentMethodContent').value }}
    </p>

    <template v-if="isNewAccount && isSendMoney" #footer>
      <AppButton theme="primary" @click="selectNewPaymentMethod">
        {{ $t('BankPaymentNotVerifiedCorp.DifferentPaymentMethodButton').value }}
      </AppButton>
      <AppButton theme="secondary" @click="onHelpCenterClick">
        {{ $t('BankPaymentNotVerifiedCorp.VisitHelpCenterButton').value }}
      </AppButton>
      <AppButton theme="secondary" @click="goToPaymentMethods">
        {{ $t('BankPaymentNotVerifiedCorp.WaitAccountVerifiedButton').value }}
      </AppButton>
    </template>

    <template v-else #footer>
      <AppButton @click="closeModal">
        {{ $t('BankPaymentNotVerified.ButtonGotIt').value }}
      </AppButton>
      <AppButton theme="secondary" @click="onHelpCenterClick">
        {{ $t('BankPaymentNotVerifiedCorp.VisitHelpCenterButton').value }}
      </AppButton>
      <!-- Keep it, for now -->
    </template>
  </information-app-modal>
</template>

<script>
import InformationAppModal, {
  ICON_TYPE,
} from '@galileo/components/InformationAppModal/InformationAppModal'

import { AppButton, useVModel } from '@oen.web.vue2/ui'
import { onBeforeMount, ref } from '@vue/composition-api'
import { SEGMENT_EVENTS, SEGMENT_LOCATIONS } from '@galileo/constants/segmentAnalytics'
import { useRouter } from '@galileo/composables/useRouter'

import { useSendMoneyStore, useI18nStore, useAnalyticsStore, useAppStore } from '@galileo/stores'

export default {
  name: 'BankPaymentNotVerifiedCorp',
  components: {
    AppButton,
    InformationAppModal,
  },
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    isNewAccount: {
      type: Boolean,
      required: false,
      default: false,
    },
    isSendMoney: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  emits: ['input'],
  setup(props, { emit }) {
    const { $t } = useI18nStore()
    const { model } = useVModel(props, emit)
    const analyticsStore = useAnalyticsStore()
    const router = useRouter()
    const appStore = useAppStore()
    const sendMoneyStore = useSendMoneyStore()

    const closeModal = () =>{
      sendMoneyStore.form.paymentMethodId = null
      emit('close')
    } 

    const onHelpCenterClick = () => {
      appStore.openHelpDesk({
        loadingRef: ref(false),
      })
    }

    const goToPaymentMethods = () => {
      router.replace({ name: 'AccountPaymentMethods' })
    }

    const selectNewPaymentMethod = () => {
      sendMoneyStore.setPaymentMethodType('BankTransfer')
      sendMoneyStore.setPaymentMethodAction('BankTransfer')
      sendMoneyStore.isPaymentMethodTypeSelected = false
      router.replace({ name: 'SendMoneyPayment' })
    }

    onBeforeMount(() => {
      analyticsStore.track({
        event: SEGMENT_EVENTS.PENDING_DIRECT_DEBIT_VERIFICATION,
        traits: {
          // location: SEGMENT_LOCATIONS.ACCOUNT,
        },
      })
    })

    return {
      model,
      $t,
      closeModal,
      ICON_TYPE,
      onHelpCenterClick,
      goToPaymentMethods,
      selectNewPaymentMethod,
    }
  },
}
</script>

<style scoped>
p {
  @apply text-base;
}
</style>
