<template>
  <information-app-modal
    v-model="model"
    button-title="Continue"
    @close="closeModal"
    :icon="ICON_TYPE.HOURGLASS"
  >
    <p>{{ $t('BankPaymentNotVerified.ParagraphText').value }}</p>

    <template #footer>
      <AppButton @click="closeModal">
        {{ $t('BankPaymentNotVerified.ButtonGotIt').value }}
      </AppButton>
      <!-- Keep it, for now -->
    </template>
  </information-app-modal>
</template>

<script>
import { useI18nStore } from '@galileo/stores'

import InformationAppModal, {
  ICON_TYPE,
} from '@galileo/components/InformationAppModal/InformationAppModal'

import { AppButton, useVModel } from '@oen.web.vue2/ui'

export default {
  name: 'BankPaymentNotVerified',
  components: {
    AppButton,
    InformationAppModal,
  },
  props: {
    value: {
      type: Boolean,
      required: true,
    },
  },
  emits: ['input'],
  setup(props, { emit }) {
    const { $t } = useI18nStore()
    const { model } = useVModel(props, emit)

    const closeModal = () => {
      model.value = false
    }

    return {
      model,
      $t,
      closeModal,
      ICON_TYPE,
    }
  },
}
</script>

<style scoped>
p {
  @apply text-base;
}
</style>
